import styled from "styled-components"

export const IndexH2 = styled.h2`
  margin-top: 1rem;
  color: #333;
  font-family: sans-serif;
  font-weight: 300;
  line-height: 1.25;
  font-size: 1.5rem;
`

export const AboutParagraph = styled.p`
  font-size: 1.25rem;
  letter-spacing: 2px;
  text-align: center;
`
