import React from "react"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Partners } from "./Partners/Partners"
import slugify from "slugify"

const InvestItem = styled(Col)`
  display: block;
  height: 33vh;
  background: url(${props => props.image});
  background-size: cover;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  && {
    display: block;
    padding: 0;
  }
`

const InvestLink = styled(Link)`
  text-shadow: none;
`

const InvestTitle = styled.p`
  display: inline-block;
  padding: 0.3rem 0.5rem;
  background: #aa0000;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`

const InvestLocation = styled.p`
  display: inline-block;
  padding: 0.3rem 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  background: #fafafa;
  text-transform: uppercase;
  font-size: 0.75rem;
`

export const Invests = () => {
  const { allContentfulInvests: data } = useStaticQuery(graphql`
    query {
      allContentfulInvests {
        edges {
          node {
            heroImage {
              fixed(width: 400) {
                src
              }
            }
            investName
            locationName
          }
        }
      }
    }
  `)

  return (
    <Row>
      {data.edges.map(({ node }) => (
        <Col sm="12" md="6" lg="4">
          <InvestLink
            to={`/invests/${slugify(`${node.investName} ${node.locationName}`, {
              lower: true,
            })}`}
          >
            <InvestItem key={node.investName} image={node.heroImage.fixed.src}>
              <InvestTitle>{node.investName}</InvestTitle>
              <InvestLocation>{node.locationName}</InvestLocation>
            </InvestItem>
          </InvestLink>
        </Col>
      ))}
      <Partners />
    </Row>
  )
}
