import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout } from "../components/Layout/Layout"
import { IndexH2, AboutParagraph } from "../components/Typography"
import { Row, Col } from "reactstrap"

import Experience from "../assets/experience.svg"
import Buildings from "../assets/buildings.svg"
import People from "../assets/people.svg"
import { Invests } from "../components/Invests/Invests"
import { Slider } from "../components/Slider/Slider"

const AboutContainer = styled(Row)`
  margin-top: 2rem;
`

const AboutColumn = styled(Col)`
  margin-bottom: 2rem;
`

const AboutImage = styled.img`
  display: block;
  width: 30%;
  height: auto;
  margin: 0 auto 1rem auto;
`

const IndexPage = () => (
  <Layout homepage>
    <SEO title="Strona główna" />
    <Slider />
    <IndexH2>
      Działamy na rynku od 1986 roku. <br /> Przez ten czas rozwineliśmy naszą
      działalność w takich branżach jak budownictwo mieszkaniowe, handel
      materiałami budowlanymi, sprzedaż mieszań oraz spedycja i transport
      międzynarodowy. Dzięki naszej wyspecjalizowanej kadrze dostarczamy państwu
      usługi o najwyższej jakości.
    </IndexH2>
    <AboutContainer>
      <AboutColumn sm="12" md="4">
        <AboutImage src={Experience} alt="" />
        <AboutParagraph>
          Posiadamy ponad 30 lat doświadczenia w branży budowlanej
        </AboutParagraph>
      </AboutColumn>
      <AboutColumn sm="12" md="4">
        <AboutImage src={Buildings} alt="" />
        <AboutParagraph>
          Zbudowaliśmy dziesiątki budynków mieszkalno-handlowych
        </AboutParagraph>
      </AboutColumn>
      <AboutColumn sm="12" md="4">
        <AboutImage src={People} alt="" />
        <AboutParagraph>
          Zatrudniamy ponad 400 osób w różnych dziedzinach
        </AboutParagraph>
      </AboutColumn>
    </AboutContainer>
    <Invests></Invests>
  </Layout>
)

export default IndexPage
