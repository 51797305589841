import React from "react"
import { Row, Col } from "reactstrap"

export const Partners = () => {
  return (
    <Col sm="12" md="2" lg="4">
      <Row>
        <Col sm="12">Partnerzy</Col>
      </Row>
    </Col>
  )
}
